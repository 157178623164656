<template>
  <div class="app-container">
    <CrudTable
      ref="table"
      entity="Accesslog"
      :columns="columns"
      use-pagination
      :allow-create="false"
      :allow-actions="false"
      :on-edit="() => {}"
      :generate-summary="generateSummary"
    />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';

export default {
  name: 'AccessLogList',
  components: { CrudTable },
  data() {
    return {
      dialogVisible: false,
      editorProps: {},
      columns: [
        { field: 'accessedAt', label: 'accesslog.accessedAt', width: 140, type: 'dateTime' },
        { field: 'accessedBy', label: 'accesslog.accessedBy', width: 140 },
        { field: 'entity', label: 'accesslog.entity', width: 150 },
        { field: 'entityID', label: 'accesslog.entityID', width: 80 },
        { field: 'ip', label: 'accesslog.ip', width: 150, sortable: false },
        { field: 'parameters', label: 'accesslog.parameters', showOverflowTooltip: true }
      ]
    };
  },
  methods: {
    generateSummary() {
      return `item`;
    }
  }
};
</script>

<style scoped></style>
